
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoginView',
  data() {
    return {
      status: 'ok'
    }
  },
  methods: {
    async authenticate(formValues: {login: string; password: string}) {
      if (formValues.login && formValues.password) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ identifier: formValues.login, password: formValues.password })
        };

        try {
          const response = await fetch(this.$ContentManagerUrl+"/auth/local/", requestOptions)
          const data = await response.json()
          
          if(data.jwt) {
            console.log('Login successful : '+data.user.role.name)
            console.log(data)
            sessionStorage.setItem('jwt', data.jwt)
            sessionStorage.setItem('id', data.user.id)
            sessionStorage.setItem('username', data.user.username)
            sessionStorage.setItem('role', data.user.role.name)
              
            console.log(sessionStorage)
            this.$router.push({ name: 'AdminHome' })
          } else {
            console.log('Logins are wrong')
            this.status = 'error'
          }


        } catch(err) {
          console.log("Error with authenticating request.")
          console.log(err)
          this.status = 'error'
        }

        

        /*const requestOptions2 = {
          method: "GET",
          headers: { "Authorization": "Bearer "+ data.jwt}
        };

        const response2 = await fetch(this.$ContentManagerUrl+"/users", requestOptions2);
        const data2 = await response2.json();
        console.log(data2)*/

      } else {
        console.log("Invalid login submitted")
      }
    }
  },
  /*computed: {
    loginStatus() {

    }
  }*/
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SiteNavigation = _resolveComponent("SiteNavigation")!
  const _component_HomeVideo = _resolveComponent("HomeVideo")!
  const _component_SiteHeader = _resolveComponent("SiteHeader")!
  const _component_BiensDisplay = _resolveComponent("BiensDisplay")!
  const _component_ContactForms = _resolveComponent("ContactForms")!
  const _component_SiteFooter = _resolveComponent("SiteFooter")!
  const _component_JbMainContainer = _resolveComponent("JbMainContainer")!

  return (_openBlock(), _createBlock(_component_JbMainContainer, {
    p: "p-0",
    bg: "bg-white",
    onScrollPassive: _ctx.handleScroll,
    class: _normalizeClass([_ctx.scrollClass, "home"])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_SiteNavigation),
      _createVNode(_component_HomeVideo),
      _createVNode(_component_SiteHeader),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BiensDisplay),
        _createVNode(_component_ContactForms)
      ]),
      _createVNode(_component_SiteFooter)
    ]),
    _: 1
  }, 8, ["onScrollPassive", "class"]))
}
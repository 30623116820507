
import { defineComponent, computed } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable'
import getBienQuery from '@/graphql/bien/get.gql'
import { helpers } from '@/helpers'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'


// My components
import SiteNavigation from '@/components/SiteNavigation.vue'
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import VerticalTabs from '@/components/VerticalTabs.vue'
import CalendarDisplay  from '@/components/CalendarDisplay.vue'

export default defineComponent({
  name: 'Livret_selection',
  components: {
    SiteNavigation,
    SiteHeader,
    SiteFooter,
    VerticalTabs,

    CalendarDisplay
  },
  setup() {
    const route = useRoute()
    const { result } = useQuery(getBienQuery, {link: route.params.bien_name})
    const biens = useResult(result, null, data => data.biens)
    const { locale } = useI18n({ useScope: 'global' })

    const isVilla = computed(() => {
      if(biens.value) {
        const villas = ['gite-des-freres', 'la-villa-11', 'la-villa-15', 'la-villa-11b', 'la-villa-11a']

        if(villas.includes(biens.value[0].link))
          return true
        else
          return false
      } else {
        return false
      }
    })
    const isOldVilla = computed(() => {
      if(biens.value) {
        const villas = ['gite-des-freres', 'la-villa-11', 'la-villa-15']

        if(villas.includes(biens.value[0].link))
          return true
        else
          return false
      } else {
        return false
      }
    })
    const isNewVilla = computed(() => {
      if(biens.value) {
        const villas = ['la-villa-11a', 'la-villa-11b']

        if(villas.includes(biens.value[0].link))
          return true
        else
          return false
      } else {
        return false
      }
    })
    const isGite = computed(() => {
      if(biens.value) {
        if(biens.value[0].link == 'gite-le-14')
          return true
        else
          return false
      } else {
        return false
      }
    })
    const isStudio = computed(() => {
      if(biens.value) {
        if(biens.value[0].link == 'studio-le-14')
          return true
        else
          return false
      } else {
        return false
      }
    })

    const bienIs = (link: string) => {
      if(biens.value) {
        if(biens.value[0].link == link)
          return true
        else
          return false
      } else {
        return false
      }
    }

    const hasMezzanine = computed(() => {
      if(biens.value) {
        const mezzanines = ['la-villa-11', 'la-villa-15']

        if(mezzanines.includes(biens.value[0].link))
          return true
        else
          return false
      } else {
        return false
      }
    })

    const langIs = (lang: string) => {
      if(locale.value == lang)
        return true
      else
        return false
    }

    return { 
      result,
      biens,
      helpers,
      isVilla, isOldVilla, isNewVilla, isGite, isStudio, bienIs, hasMezzanine,
      langIs
   }
  },
  methods: {
  },
});


import { defineComponent, inject, ref, computed } from 'vue';
import { Emitter } from 'mitt'

import BienDisplay from '@/components/BienDisplay.vue'
import TestemoniesDisplay from '@/components/TestemoniesDisplay.vue'

import { useQuery, useResult } from '@vue/apollo-composable'
import getBiensQuery from '@/graphql/bien/gets.gql'

export default defineComponent({
  name: 'BiensDisplay',
  components: {
    BienDisplay,
    TestemoniesDisplay
  },
  setup() {
    const { result } = useQuery(getBiensQuery)
    const $emitter = inject('$emitter') as Emitter

    const biensQR = useResult(result, null, data => data.biens)

    const openModal = (name: string) => {
      $emitter.emit("open-modal-"+name)
    }
    const closeModal = (name: string) => {
      $emitter.emit("close-modal-"+name)
    }
  
    const price = ref(0)
    
    const updatePrice = (value: number) => {
      price.value = value
    }
    const priceClass = computed(() => {
      if(price.value == -1) {
        return 'error'
      } else if (price.value == 0) {
        return 'neutral'
      } else {
        return 'success'
      }
    })

    return {
      biensQR,
      openModal, closeModal,
      price, updatePrice,
      priceClass,
    }
  }
});

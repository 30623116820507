
import { defineComponent, computed } from 'vue';
import { useQuery } from '@vue/apollo-composable'
import LocaleSwitch from '@/components/LocaleSwitch.vue'
import getBienQuery from '@/graphql/bien/get.gql'

export default defineComponent({
  name: 'Livret_bien',
  props: ['bien_name'],
  components: {
    LocaleSwitch,
  },
  data() {
    return {
    }
  },
  setup(props) {
    const { result } = useQuery(getBienQuery, {"link": props.bien_name})
    console.log( { result } )

    const isVilla = computed(() => {
      if(result.value.biens) {
        const villas = ['gite-des-freres', 'la-villa-11', 'la-villa-15', 'la-villa-11b', 'la-villa-11a']

        if(villas.includes(result.value.biens[0].link))
          return true
        else
          return false
      } else {
        return false
      }
    })

    return { 
      result,
      isVilla
    }
  },
  methods: {
    scrollTo(elementId: string) {
      const element: HTMLElement | null = document.getElementById(elementId);
      if(element) 
        element.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
    },
  },
});

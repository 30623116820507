import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "inline-block px-5 py-3",
  href: "https://api.whatsapp.com/send?phone=+330641692161"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["absolute transition-all duration-500 top-[45%] origin-bottom-left rounded-t rotate-90 bg-neutral-200/70 md:hidden z-20", _ctx.leftPosition])
    }, [
      _createElementVNode("a", _hoisted_1, _toDisplayString(_ctx.$t('words.call_us')), 1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute transition-all duration-500 top-[45%] origin-bottom-right rounded-t -rotate-90 bg-neutral-200/70 md:hidden z-20", _ctx.rightPosition])
    }, [
      _createVNode(_component_router_link, {
        class: "inline-block px-5 py-3",
        to: "/reserver"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('home.menu.availability')), 1)
        ]),
        _: 1
      })
    ], 2)
  ], 64))
}
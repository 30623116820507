import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import PublicView from './views/PublicView.vue'
import HomeView from './views/public/HomeView.vue'
import AvailabilityView from './views/public/AvailabilityView.vue'
import PresentationLodgingSelectionView from './views/public/presentation/LodgingSelectionView.vue'
import PresentationView from './views/public/presentation/PresentationView.vue'
import BookletLodgingSelectionView from './views/public/booklet/LodgingSelectionView.vue'
import BookletView from './views/public/booklet/BookletView.vue'
import RulebookView from './views/public/RulebookView.vue'
import MentionsView from './views/public/MentionsView.vue'
import PartnersView from './views/public/PartnersView.vue'
import ALoginView from './views/admin/LoginView.vue'
import AdminView from './views/AdminView.vue'
  import AHomeView from './views/admin/HomeView.vue'
  import AStatistiquesView from './views/admin/StatistiquesView.vue'
  import AFacturesView from './views/admin/InvoicesView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Root',
    component: PublicView,
    children: [
      {
        path: '',
        component: HomeView
      },
      {
        path: 'reserver',
        component: AvailabilityView
      },
      {
        path: 'mentions-legales',
        component: MentionsView
      },
      {
        path: 'partenaires',
        component: PartnersView
      },
      {
        path: 'visiter',
        component: PresentationLodgingSelectionView
      },
      {
        path: 'visiter/:bien_name',
        props: true,
        component: PresentationView
      },
      {
        path: 'livret-accueil',
        component: BookletLodgingSelectionView
      },
      {
        path: 'livret-accueil/:bien_name',
        props: true,
        component: BookletView
      },
      {
        path: 'reglement',
        props: true,
        component: RulebookView
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: ALoginView,
  },
  {
    path: '/logout',
    name: 'Logout',
    redirect: '/login'
  },
  {
    path: '/admin',
    component: AdminView,
    meta: { authorize: ["Authenticated"] },
    children: [
      {
        path: 'home',
        name: 'AdminHome',
        component: AHomeView,
      },
      {
        path: 'statistiques',
        component: AStatistiquesView
      },
      {
        path: 'factures',
        component: AFacturesView
      },
    ]
  },
  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Is the route protected
  if (to.meta.authorize) {
    // So is the user connected ?
    if (sessionStorage.getItem("role")) {
      // And is the user authorized to be there ?
      if ((to.meta.authorize as any[]).includes(sessionStorage.getItem("role"))) {
        // Alright you may enter
        next();
      } else {
        // Redirect this impostor !
        next('/login');
      }
    } else {
      // Redirect this impostor !
      next('/login');
    }

    //if(sessionStorage.getItem("role"))
    //console.log("I am a "+sessionStorage.getItem("role"))

  } else {
    // If user wants to log out
    if (to.redirectedFrom) {
      if (to.redirectedFrom.name == "Logout")
        sessionStorage.clear()
    }
    next();
  }

})

export default router


import { defineComponent } from 'vue'

// My components
import SiteNavigation from '@/components/SiteNavigation.vue'
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default defineComponent({
  name: 'MentionsView',
  components: {
    SiteHeader,
    SiteNavigation,
    SiteFooter,
  },
  data() {
    return {
    }
  }
});


import { defineComponent } from 'vue';
import { useQuery } from '@vue/apollo-composable'
import getBiensQuery from '@/graphql/bien/gets.gql'
import { helpers } from '@/helpers'

// My components
import SiteNavigation from '@/components/SiteNavigation.vue'
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import VerticalTabs from '@/components/VerticalTabs.vue'

export default defineComponent({
  name: 'Livret_selection',
  components: {
    SiteNavigation,
    SiteHeader,
    SiteFooter,
    VerticalTabs
  },
  data() {
    return { }
  },
  setup() {
    const { result } = useQuery(getBiensQuery)
    return { 
      result,
      helpers
   }
  }
});

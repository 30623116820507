
import { defineComponent, inject, ref, reactive } from 'vue'
import { useQuery, useResult } from '@vue/apollo-composable'

import getLastLodgifyImportQuery from '@/graphql/common/get_lastLodgifyImport.gql'


export default defineComponent({
  name: 'AdminHome',
  setup() {
    // get emitter
    const $ContentManagerUrl = inject('$ContentManagerUrl')

    // get total Reservations from Lodgify
    const reservations = reactive({lodgify_response:{}})
    const totalUpcomingReservations = ref(0)
    const totalReservations = ref(0)
    const lodgifyGetTotalReservations = async() => {

      // Get all Upcoming 
      const response = await fetch($ContentManagerUrl+"/lodgify-calls/total-reservations?"+ new URLSearchParams({
        includeCount: 'true',
        stayFilter: 'Upcoming',
        includeExternal: 'true',
        page: '10', // It's the minimum
        size: '1'
      }), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('jwt')}`
        },
      })

      if(response.ok) {
        const data = await response.json()

        totalUpcomingReservations.value = data.count
      } else {
        throw Error(response.statusText);
      }

      const response2 = await fetch($ContentManagerUrl+"/lodgify-calls/total-reservations?"+ new URLSearchParams({
        includeCount: 'true',
        stayFilter: 'All',
        size: '10',
        page: '1'
      }), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('jwt')}`
        },
      })

      if(response2.ok) {
        const data2 = await response2.json()

        totalReservations.value = data2.count
      } else {
        throw Error(response.statusText);
      }
    }

    let loadingReservations = ref(false)
    const lodgifyImportReservations = async(page: number) => {
      loadingReservations.value = true
      const response = await fetch($ContentManagerUrl+"/lodgify-calls/import-reservations", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('jwt')}`
        },
      })

      if(response.ok) {
        reservations.lodgify_response = await response.json()
        refetchLastLodgifyImport()
      } else {
        throw Error(response.statusText);
      }

      loadingReservations.value = false
    }

    // Get last Lodgify import date
    const { result: getLastLodgifyImportResult, refetch: refetchLastLodgifyImport } = useQuery(getLastLodgifyImportQuery)
    const LastLodgifyImport = useResult(getLastLodgifyImportResult, null, data => new Date(data.commonInfo.lodgify_last_import).toLocaleString('FR-fr', { weekday: "long", day: "numeric", month: 'long', year:'numeric' }))


    lodgifyGetTotalReservations()

    return {
      reservations,
      loadingReservations,
      lodgifyImportReservations,
      totalReservations,
      totalUpcomingReservations,
      LastLodgifyImport
    }
  }
})

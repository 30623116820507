
import { defineComponent, ref, reactive, inject } from 'vue'
import { Emitter } from 'mitt'
import { useMutation } from '@vue/apollo-composable'
import { helpers, invoiceHelpers } from '@/modules/helpers'
import { searchValueNulllIfZero } from '@/modules/search'
import createInvoiceMutation from '@/graphql/invoice/create.gql'
import generateInvoiceMutation from '@/graphql/invoice/generate.gql'
import generateCreditNoteMutation from '@/graphql/invoice/generateCreditNote.gql'

import InvoiceForm from '@/components/forms/Invoice.form.vue'


export default defineComponent({
  name: 'InvoiceCreate',
  components: {
    InvoiceForm
  },
  setup() {
    const emitter = inject('$emitter') as Emitter

    const invoiceFormRef = ref<null | any>(null)

    // Apollo Create Query 
    const { mutate: createInvoice, onDone: createInvoiceDone, onError: createInvoiceError } = useMutation(createInvoiceMutation)
    const createStatus = reactive({
      success: false,
      error: false
    })

    createInvoiceDone((QR) => {
      createStatus.success = true
      createStatus.error = false
      emitter.emit("create-toast", {
        props: {
          title: "Facture créée",
          text: QR.data.createInvoice.invoice.name,
          icon: "check-circle",
        }
      })
      emitter.emit("close-modal-invoice-create")
      emitter.emit("invoices-refresh")
    })
    createInvoiceError(() => {
      createStatus.error = true
      createStatus.success = false
      console.log('Create invoice Error')
    })

    
    const { mutate: createInvoiceAndGenerate, onDone: createInvoiceAndGenerateDone, onError: createInvoiceAndGenerateError } = useMutation(createInvoiceMutation)
    createInvoiceAndGenerateDone((QR) => {
      console.log(QR)
      createStatus.success = true
      createStatus.error = false
      emitter.emit("create-toast", {
        props: {
          title: "Facture créée",
          text: QR.data.createInvoice.invoice.name,
          icon: "check-circle",
        }
      })
      emitter.emit("close-modal-invoice-create")
    
      generateInvoice(QR.data.createInvoice.invoice.id)
      generateCreditNote(QR.data.createInvoice.invoice.id)

      emitter.emit("invoices-refresh")

    })
    createInvoiceAndGenerateError(() => {
      createStatus.error = true
      createStatus.success = false
      console.log('Create invoice Error')
    })


    // generate Invoice PDF
    const { loading: generateInvoiceLoading, mutate: _generateInvoice, onDone: generateInvoiceDone } = useMutation(generateInvoiceMutation)

    const generateInvoice = (id: number) => {
      const queryInput = {
        id: id
      }
      _generateInvoice(queryInput)
    }
    generateInvoiceDone(() => {
      emitter.emit("create-toast", {
        props: {
          title: 'PDF Généré',
          text: "Le PDF de la facture a été généré avec succès.",
          icon: "check-circle",
        }
      })
    })

    // generate CreditNote PDF
    const { loading: generateCreditNoteLoading, mutate: _generateCreditNote, onDone: generateCreditNoteDone } = useMutation(generateCreditNoteMutation)

    const generateCreditNote = (id: number) => {
      const queryInput = {
        id: id
      }
      _generateCreditNote(queryInput)
    }
    generateCreditNoteDone(() => {
      emitter.emit("create-toast", {
        props: {
          title: 'PDF Généré',
          text: "Le PDF de la facture d'avoir a été généré avec succès.",
          icon: "check-circle",
        }
      })
    })


    ////////// SUBMIT Modal
    const submit_modal = (generatePDFs = false) => {
      const form = invoiceFormRef.value.form
      
      const custom_products: any[] = invoiceHelpers.customProductsToStrapi(form.custom_products)

      const reservationId = searchValueNulllIfZero(form.reservationId)

      let date: string | null = helpers.dateobjToISO(new Date())
      if(form.date) {
        date = helpers.dateobjToISO(form.date)
      }

      let client_arrival: string | null = null
      if(form.client_arrival) {
        client_arrival = helpers.dateobjToISO(form.client_arrival)
      }

      let client_departure: string | null = null
      if(form.client_arrival) {
        client_departure = helpers.dateobjToISO(form.client_departure)
      }
      
      const queryInput = { 
        input: { 
          data: {
            name: form.name,
            custom_products: JSON.parse(JSON.stringify(custom_products)),
            client_name: form.client_name,
            client_email: form.client_email,
            client_phone: form.client_phone,
            date: date,
            client_arrival: client_arrival,
            client_departure: client_departure,
            paid: form.paid,
            due: form.due,
            reservation: reservationId,
            sci: form.sciId
          }
        }
      }


      if(generatePDFs) {
        console.log('generate !')
        createInvoiceAndGenerate(queryInput)
      } else {
        createInvoice(queryInput)
      }
    }

      // Testing purposes
    const fillForm = () => {
      invoiceFormRef.value.fillForm()
    }

    return {
      invoiceFormRef,
      createInvoice,
      createStatus,

      submit_modal,
      fillForm
    }
  }
});

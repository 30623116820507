
import { defineComponent } from 'vue';
//import Multiselect from '@vueform/multiselect'
export default defineComponent({
  name: 'PublicView',
  data() {
    return {
      selectValue: 2,
      range : {
        start: new Date(),
        end: new Date()
      },
      modelConfig: {
        timeAdjust: '12:00:00',
      },
      scrollClass: "scrolled-top",
      containerElement: 'a',
      navigationToggled: false
    }
  },
  methods: {
    handleScroll() {
      console.log(this.$router)
      const mainContainerElement: HTMLCollection | null = document.getElementsByClassName("jb-main-container")
      if (mainContainerElement[0].scrollTop > 50 ) {
        this.scrollClass = "scrolling"
      } else {
        this.scrollClass = "scrolled-top"
      }
    }
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c8f6ab8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "page" }
const _hoisted_2 = {
  key: 0,
  id: "all-lodgings"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "icons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SiteHeader = _resolveComponent("SiteHeader")!
  const _component_VerticalTabs = _resolveComponent("VerticalTabs")!
  const _component_SiteNavigation = _resolveComponent("SiteNavigation")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SiteFooter = _resolveComponent("SiteFooter")!
  const _component_JbMainContainer = _resolveComponent("JbMainContainer")!

  return (_openBlock(), _createBlock(_component_JbMainContainer, {
    p: "p-0",
    bg: "bg-white",
    class: "visiter"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_SiteHeader),
      _createVNode(_component_VerticalTabs),
      _createVNode(_component_SiteNavigation),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('visit.title')), 1),
        (_ctx.result && _ctx.result.biens)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.result.biens, (bien) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  to: '/visiter/'+bien.link,
                  key: bien,
                  class: "lodging"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: _ctx.helpers.cm_image(bien.image, _ctx.$ContentManagerUrl)
                    }, null, 8, _hoisted_3),
                    _createElementVNode("div", null, [
                      _createElementVNode("p", _hoisted_4, _toDisplayString(bien.name), 1),
                      _createElementVNode("p", _hoisted_5, [
                        _createElementVNode("span", null, [
                          _createVNode(_component_font_awesome_icon, { icon: "bed" }),
                          _createTextVNode(_toDisplayString(bien.nbRooms), 1)
                        ]),
                        _createElementVNode("span", null, [
                          _createVNode(_component_font_awesome_icon, { icon: "users" }),
                          _createTextVNode(_toDisplayString(bien.maxUsers), 1)
                        ])
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["to"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_SiteFooter)
    ]),
    _: 1
  }))
}
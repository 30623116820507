import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16e885d5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "bien",
    to: '/livret-accueil/'+_ctx.bien.link
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("img", {
          class: "photo",
          src: _ctx.$ContentManagerUrl+_ctx.bien.image.url
        }, null, 8, _hoisted_1)
      ]),
      _createElementVNode("p", null, _toDisplayString(_ctx.bien.name), 1)
    ]),
    _: 1
  }, 8, ["to"]))
}

import { defineComponent } from 'vue'
import SiteHeader from '@/components/SiteHeader.vue'
import SiteNavigation from '@/components/SiteNavigation.vue'
import AvailabilityFull from '@/components/AvailabilityFull.vue'
import SiteFooter from '@/components/SiteFooter.vue'
//import { DatePicker } from 'v-calendar'

export default defineComponent({
  name: 'AvailabilityView',
  components: {
    SiteHeader,
    SiteNavigation,
    AvailabilityFull,
    SiteFooter
  },
  data() {
    return {
      selectValue: 2,
      range : {
        start: new Date(),
        end: new Date()
      },
      modelConfig: {
        timeAdjust: '12:00:00',
      },
      scrollClass: "scrolled-top",
      containerElement: 'a',
      navigationToggled: false
    }
  },
  methods: {
    goToAvailableBien() {
      const elements: HTMLCollection | null = document.getElementsByClassName("book-btn success")
      //console.log(elements)
      if(elements.length > 0) {
        elements[0].scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
      } else {
        const multipleInfosDiv: HTMLElement | null  = document.getElementById("multiple-infos")
        if(multipleInfosDiv) {
          multipleInfosDiv.scrollIntoView({behavior: "smooth", block: "start", inline: "start"})
        }
      }
    },
    handleScroll() {
      const mainContainerElement: HTMLCollection | null = document.getElementsByClassName("jb-main-container")
      if (mainContainerElement[0].scrollTop > 50 ) {
        this.scrollClass = "scrolling"
      } else {
        this.scrollClass = "scrolled-top"
      }
    }
  },
  watch: {
    range() {
      this.$store.commit('selectGlobalDate', this.range)
    }
  }
});

// disable linter camelCase BS
/*eslint-disable */
export const en = {
  home: {
    menu: {
      video: "Home",
      availability: "Book online",
      visit: "Visit",
      testemonies: "Testemonies",
      partners: "Partners",
      contact: "Contact",
      fees: "15% additional charge"
    },
    video: {
      descriptions: {
        1: {
          highlight1: "Beautiful villa",
          normal1: "for up to",
          highlight2: "15 people"
        },
        2: {
          normal1: "A totally private",
          highlight1: "SPA area",
          normal2: "accessible",
          highlight2: "24 hours a day",
        },
        3: {
          normal1: "Located just",
          highlight1: "5 minutes from Strasbourg",
        },
        4: {
          normal1: "Have a good time",
          highlight1: "with family",
          normal2: "or",
          highlight2: "friends",
        }
      },
      title: "Five high quality airbnb lodgings for your holidays, only five minutes away from Strasbourg!",
    },
    availability: {
      title: "Book our lodgings",
      h2: "See at a glance our available slots",
      notes: {
        title:"Note",
        direct: "Booking directly from this site guarantees you the best possible price on the booking, as you avoid the platform fees that are inevitably passed on to the customer.",
        duration: "The minimum possible reservation period is two consecutive days, except Sunday evening (this rule does not apply to Studio 14).",

        options: {
          title: "Options",
          jacuzzi: "Access to the spa is subject to payment of a flat rate of 50 € for the stay for all the equipment (jacuzzi, sauna and Netflix overhead projector).",
          animals: "Animals are accepted. However, they incur an additional cleaning cost of 30 € for the stay."
        },
        fees: {
          title: "Additional charges",
          cleaning: "A cleaning fee of 80/40/20 € (for a House/Apartment/Studio respectively) is applied to all reservations.",
          tourist_tax: "A tourist tax of € 1 per person per day is mandatory for all reservations."
        },
        other: {
          title: "Other remarks",
          payment: "At the time of booking, only € 1 is paid from your credit card. The remainder due will be taken after the end of your stay.",
          see_booklet: "You can consult all of our reception conditions in our",
          see_ruleset: "as well as our regulations in our"
        }
      },
      popovers: {
        0: "Day booked",
        1: "Can be your trip's end date",
        2: "Date open"
      },
      tooManyUsers: "This lodging has a maximum capacity of {0} people",
      button: {
        book: "Book online",
        details: "More details",
        loading: "Searching...",
        select: "Select a date range...",
        error: "Lodging unavailable, please try another date range"
      }
    },
    presentation: {
      title: "Discover our airbnb's",
      minStay: "The minimum stay is two days except on sunday evening.",
      nominStay: "There is no minimum stay for this lodging."
    },
    testemonies: {
      title: "Customer reviews"
    },
    contact: {
      title: "Need more information?",
      name: "Your full name",
      email: "Your e-mail address",
      by_mail: {
        subTitle: "By email",
        fill_form: "Please fill this form and we will get back to you as soon as possible."
      },
      by_phone: {
        subTitle: "Call us at",
        call: "By phone",
        your_number: "Your phone number",
        called_back: "Or, if you want to be called back, leave us your phone number and we will contact you withing the day.",
      },
      message: "Type your message here",
      send: "Send",
      sent: "Message sent!"
    },
    footer: {
      legal_notice: {
        name: "Legal notice"
      },
      welcome_booklet: {
        name: "Welcome booklet"
      },
      rules_booklet: {
        name: "Internal ruleset"
      }
    },
    selectDate: "Dates",
    selectNumber: "Number of travelers",
    search_btn: "Search"
  },
  visit: {
    title: "Visit our airbnbs",
    icons: {
      spa: "Spa area",
      jacuzzi: "Jacuzzi",
      sauna: "Sauna",
      projector: "Overhead projector with Netflix",
      arcade: "Arcade cabinet",
      barbecue: "Barbecue",
      aircon: "Air conditioning",
      petanque: "Petanque",
      futbol: "Futbol",
      table_tennis: "Table tennis",
      wifi: "Wi-fi",
      fridge: "Fridge with freezer",
      american_fridge: "American fridge",
      small_fridge: "Small fridge",
      dishwasher: "Dishwasher",
      oven: "Oven",
      microwave: "Microwave",
      coffee_maker: "{n} Coffee machine | {n} Coffee machines",
      ustensils: "Cutlery for {0} persons"
    }
  },
  cookies: {
    sentence: "We use cookies to provide the services and features offered on our site and to improve the experience of our users. Cookies are stored on your computer or any other device and will never be used for other purposes.",
    button: "I understand and I accept."
  },
  words: {
    and: "and",
    for: "for",
    bedroom: "bedroom | bedrooms",
    beds: {
      simple: "simple bed | simple beds",
      double: "double bed | double beds",
      couch: "couch bed | couch beds",
    },
    person: "person | people",
    call_us: "Call us",
    villa: "The villa",
    apartment: "The apartment",
    studio: "The studio",
  },
  chapters: "Chapters",
  titles: {
    welcome: {
      h2: "Welcome !"
    },
    beforeArrival: {
      h2: "Before your arrival",
      howToFind: {
        h3: "How to get there ?",
        address: "The address",
        car: "By car",
        train: "By train",
        bus: "By bus",
        plane: "By plane"
      },
      hours: {
        h3: "Hours"
      }
    },
    onArrival: {
      h2: "Upon your arrival",
      whereToPark: {
        h3: "Where to Park ?"
      },
      goldenRules: {
        h3: "Our three golden rules"
      },
      products: {
        h3: "Provided products / product to plan for"
      },
      payment: {
        h3: "Rental payment (if booked directly from us)"
      },
      keys: {
        h3: "Key handover"
      }
    },
    manuals: {
      h2: "Equipment user manuals",
      jacuzzi: {
        h3: "Jacuzzi"
      },
      sauna: {
        h3: "Sauna"
      },
      barbecue: {
        h3: "Barbecue"
      },
      wifi: {
        h3: "Wifi networks"
      },
      dishwasher: {
        h3: "Dishwasher"
      },
      toilets: {
        h3: "Sanitary facilities"
      },
      sorting_trash: {
        h3: "Sorting waste"
      },
      glass_trash: {
        h3: "Glass waste"
      }
    },
    practicalInfo: {
      h2: "Practical informations",
      shops: {
        h3: "Grocery shopping and gas"
      },
      shops_sunday: {
        h3: "On Sunday"
      },
      doctors: {
        h3: "Doctors, hospitals, pharmacies"
      },
      other: {
        h3: "Others"
      }
    },
    goToPlaces: {
      h2: "Go-to places",
      restaurant_selection: {
        h3: "Our selection of restaurants"
      },
      other_restaurants: {
        h3: "Other restaurants"
      },
      gourmet_addresses: {
        h3: "Our addresses for gourmets",
        cheese: {
          h4: "Cheese"
        },
        wines: {
          h4: "Wine"
        },
        bakeries: {
          h4: "Bakeries"
        },
        pastries: {
          h4: "Pastries"
        },
        caterers: {
          h4: "Caterers"
        },
        vegetables: {
          h4: "Vegetables"
        },
        flowers: {
          h4: "Florist"
        }
      },
    },
    closeActivities: {
      h2: "Close activities",
      amusement_parcs: {
        h3: "Amusement parcs"
      },
      museeums: {
        h3: "Museeums / Monuments"
      },
      sport_activities: {
        h3: "Outdoor activities"
      },
      other: {
        h3: "Others"
      }
    },
    uponLeaving: {
      h2: "Upon leaving",
      furniture: {
        h3: "Furniture"
      },
      equipment: {
        h3: "Amenities"
      },
      windows: {
        h3: "Windows and lights"
      },
      trash: {
        h3: "Trash cans"
      },
      towels: {
        h3: "Sheets and towels"
      },
      rate_us: {
        h3: "Rate us!"
      }
    },
    other_mentions: {
      h2: "Other mentions",
      video_surveillance: {
        h3: "Video surveillance"
      },
      fire_safety: {
        h3: "Fire safety notice"
      },
      remark: {
        h3: "Note"
      }
    },
    closing_words: {
      h2: "Closing words"
    },
    behavior: {
      name: "Behavior"
    },
    noise: {
      name: "Noise"
    },
    tobbaco: {
      name: "Tobbaco"
    },
    upkeep: {
      name: "Upkeep"
    },
    trash: {
      name: "Trash"
    },
    rooms: {
      name: "Rooms"
    },
    water_electricity_heating: {
      name: "Water, Electricity, Heating"
    },
    pets: {
      name: "Pets"
    },
    toilets: {
      name: "Toilets"
    },
    bbq: {
      name: "Barbecue"
    },
    insurance: {
      name: "Insurance"
    },
    fire_safety: {
      name: "Fire Safety"
    },
    leaving: {
      name: "Leaving"
    },
    jacuzzi: {
      name: "Jacuzzi"
    },
    sauna: {
      name: "Sauna"
    },
    caution_table: {
      name: "Caution table"
    },
    sort_guide: {
      name: "Local trash sorting guide"
    },
    glass_bins: {
      name: "Glass bin locations"
    },
    jacuzzi_manual: {
      name: "Jacuzzi user manual"
    }
  },
  biens: {
    pageTitle: "Gites & Spa Strasbourg",
    bien_select_text: "Please select the place you are currently staying in:",
    welcome_booklet: {
      pageTitle: "Welcome booklet"
    },
    rules_booklet: {
      pageTitle: "Internal ruleset",
      equipment_notice: "Operating manual",
      see_on_map: "View on Google Maps"
    },
    h2: {
      overview: "Overview",
      other: "In short",
      reviews: "Recognized quality",
      convinced: "Convinced ? Book online now !"
    },
    'gite-des-freres': {
      short: "250 sqm. villa with Private spa, Sauna, Barbecue, Babyfoot, Pétanque and more",
      long: {
        intro: "",
      },
      presentation: {
        title: "An exceptional Villa",
        h2: {
          activities: "Recreation for all ages",
          rooms: "Rooms worthy of an hotel",
          equipments: "Many equipments",
        },
      },
      photos_captions: {
        spa: "The 5-seater jacuzzi.",
        spa_space: "Small common area near the jacuzzi.",
        sauna: "The 3-seater sauna",
        futbol: "Top-of-the-range Sulpice table football.",
        table_tennis: "Indoor ping-pong table.",
        petanque: "Pétanque court.",
        arcade: "Arcade machine with more than 3000 games available.",
        barbecue: "Weber gas barbecue.",
        coffee: "The four coffee machines.",
        bathroom: "The spacious bathroom.",
        living: "Air-conditioned living room.",
        room1: "Room 1",
        room2: "Room 2",
        room3: "Room 3",
        room4: "Room 4",
        room5: "Room 5",
        room6: "Room 6"
      }
    },
    'la-villa-11': {
      short: "180 sqm. villa with Private spa, Sauna, Barbecue, Babyfoot, Pétanque and more",
      long: {
        intro: "",
      },
      presentation: {
        title: "An exceptional Villa",
        h2: {
          activities: "Recreation for all ages",
          rooms: "Rooms worthy of an hotel",
          equipments: "Many equipments",
        },
      },
      photos_captions: {
        spa: "The 5-seater jacuzzi.",
        spa_space: "Spa area with sauna, jacuzzi and Netflix overhead projector.",
        futbol: "Top-of-the-range Sulpice table football.",
        terrace: "Terrace with deckchairs.",
        exterior: "Large outdoor space.",
        exterior2: "A large table and a Weber barbecue are available for your meals.",
        table_tennis: "Ping-pong table.",
        petanque: "Deckchairs and pétanque court.",
        arcade: "Arcade machine with more than 3000 games available.",
        equipment: "An overview of the available equipment.",
        bathroom: "The spacious bathroom.",
        kitchen: "Large fully equipped kitchen.",
        room1: "Room 1",
        room2: "Room 2",
        room3: "Room 3",
        room4: "Room 4",
        room5: "Room 5",
      }
    },
    'la-villa-11a': {
      short: "230 sqm. villa with Private spa, Sauna, Babyfoot, Arcade and more",
      long: {
        intro: "",
      },
      presentation: {
        title: "An exceptional Villa",
        h2: {
          activities: "Recreation for all ages",
          rooms: "Rooms worthy of an hotel",
          equipments: "Many equipments",
        },
      },
      photos_captions: {
        spa: "The 5-seater jacuzzi.",
        spa_space: "Spa area with sauna, jacuzzi and Netflix overhead projector.",
        futbol: "Top-of-the-range Sulpice table football.",
        table_tennis: "Ping-pong table.",
        arcade: "Arcade machine with more than 3000 games available.",
        equipment: "An overview of the available equipment.",
        bathroom: "The spacious bathroom.",
        kitchen: "Large fully equipped kitchen.",
        room1: "Room 1",
        room2: "Room 2",
        room3: "Room 3",
        room4: "Room 4",
      }
    },
    'la-villa-11b': {
      short: "240 sqm. villa with Private spa, Sauna, Babyfoot, Arcade and more",
      long: {
        intro: "",
      },
      presentation: {
        title: "An exceptional Villa",
        h2: {
          activities: "Recreation for all ages",
          rooms: "Rooms worthy of an hotel",
          equipments: "Many equipments",
        },
      },
      photos_captions: {
        spa: "The 5-seater jacuzzi.",
        spa_space: "Spa area with sauna, jacuzzi and Netflix overhead projector.",
        futbol: "Top-of-the-range Sulpice table football.",
        table_tennis: "Ping-pong table.",
        arcade: "Arcade machine with more than 3000 games available.",
        equipment: "An overview of the available equipment.",
        bathroom: "The spacious bathroom.",
        kitchen: "Large fully equipped kitchen.",
        room1: "Room 1",
        room2: "Room 2",
        room3: "Room 3",
        room4: "Room 4",
      }
    },
    'la-villa-15': {
      short: "200 sqm. villa with Private spa, Sauna, Barbecue, Babyfoot, Pétanque and more",
      long: {
        intro: "",
      },
      presentation: {
        title: "An exceptional Villa",
        h2: {
          activities: "Recreation for all ages",
          rooms: "Rooms worthy of an hotel",
          equipments: "Many equipments",
        },
      },
      photos_captions: {
        spa: "Spa area with sauna, jacuzzi and Netflix overhead projector.",
        spa_space: "The corner sauna.",
        futbol: "Top-of-the-range Sulpice table football.",
        table_tennis: "Outdoor ping-pong table.",
        petanque: "Pétanque court.",
        arcade: "Arcade machine with more than 3000 games available.",
        equipment: "An overview of the available equipment.",
        raclette: "The raclette machine.",
        bathroom: "The spacious bathroom.",
        kitchen: "Large fully equipped kitchen.",
        room1: "Room 1",
        room2: "Room 2",
        room3: "Room 3",
        room4: "Room 4",
        room5: "Room 5",
        room6: "Room 6"
      }
    },
    'gite-le-14': {
      short: "Modern apartment with jacuzzi, private terrace, air conditioning...",
      long: {
        intro: "",
      },
      presentation: {
        title: "A nice and comfortable apartment",
        h2: {
          activities: "Recreation for all ages",
          rooms: "Rooms designed for your comfort",
          equipments: "Many equipments",
        },
      },
      photos_captions: {
        rating: "Gite certified 4 stars.",
        spa: "The 6-seater jacuzzi and the terrace.",
        equipment: "An overview of the available equipment.",
        raclette: "The raclette machine.",
        kitchen: "Large fully equipped kitchen.",
        coffee: "The four coffee machines.",
        bathroom: "The bathroom with Sauna in red maple.",
        barbecue: "Weber gas barbecue.",
        exterior: "Enjoy a beautiful furnished exterior for your dinners.",
        room1: "Room 1",
        room2: "Room 2"
      }
    },
    'studio-le-14': {
      short: "Modern studio with jacuzzi, private terrace, air conditioning...",
      long: {
        intro: "",
      },
      presentation: {
        title: "The ideal studio for your vacation",
        h2: {
          activities: "Recreation for all ages",
          rooms: "Rooms designed for your comfort",
          equipments: "Quality furniture",
        },
      },
      photos_captions: {
        spa: "The 5-seater jacuzzi and the small terrace.",
        living: "Beautiful air-conditioned living room with Samsung \"The Frame\"TV",
        living2: "Overview of the studio.",
        kitchen: "Functional kitchen with microwave.",
        bathroom: "Sanitary and bathroom.",
        equipment: "An overview of the available equipment."
      }
    },
    'villa-le-14': {
      short: "170 sqm. villa with Private spa, Sauna, Barbecue, Babyfoot, Pétanque and more",
      long: {
        intro: "",
      },
      presentation: {
        title: "An exceptional Villa",
        h2: {
          activities: "Recreation for all ages",
          rooms: "Rooms worthy of an hotel",
          equipments: "Many equipments",
        },
      }
    },
    
  },
  partners: {
    name: "Our partners",
    discover: "Discover",
    sainte_maxime: "Very nice appartment located in Sainte-Maxime on the Azur seaside. It is equipped with terrace, pool, spa, gym, balneo and sauna for a garanteed stay of relaxation."
  }
}
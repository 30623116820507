
import { defineComponent } from 'vue'

// My components
import SiteNavigation from '@/components/SiteNavigation.vue'
import HomeVideo from '@/components/HomeVideo.vue'
import SiteHeader from '@/components/SiteHeader.vue'
import BiensDisplay from '@/components/BiensDisplay.vue'
import ContactForms from '@/components/ContactForms.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default defineComponent({
  name: 'HomeView',
  components: {
    SiteNavigation,
    SiteHeader,
    HomeVideo,
    BiensDisplay,
    ContactForms,
    SiteFooter
  },
  data() {
    return {
      selectValue: 2,
      range : {
        start: new Date(),
        end: new Date()
      },
      modelConfig: {
        timeAdjust: '12:00:00',
      },
      scrollClass: "scrolled-top",
      containerElement: 'a',
      navigationToggled: false
    }
  },
  methods: {
    handleScroll() {
      const mainContainerElement: HTMLCollection | null = document.getElementsByClassName("jb-main-container")
      if (mainContainerElement[0].scrollTop > 50 ) {
        this.scrollClass = "scrolling"
      } else {
        this.scrollClass = "scrolled-top"
      }
    }
  },
});

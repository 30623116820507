
import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
  name: 'VerticalTabs',
  data() {
    return {
      locale: '',
    }
  },
  setup() {
    const mainContainerElement: HTMLCollection | null = document.getElementsByClassName("jb-main-container")

    onMounted(() => {
      mainContainerElement[0].addEventListener('scroll', (event) => {
        handleScroll()
      })
    })

    let leftPosition = ref("left-0")
    let rightPosition = ref("right-0")

    let g_timer : any = null

    const handleScroll = () => {
      slideTabsOut()

      clearTimeout(g_timer)
      startTimer()
    }

    function startTimer() {
      g_timer = window.setTimeout(function() {
          slideTabsIn()
      }, 500);
    }


    const slideTabsOut = () => {
      leftPosition.value = "left-[-100px]"
      rightPosition.value = "right-[-100px]"
    }
    const slideTabsIn = () => {
      leftPosition.value = "left-0"
      rightPosition.value = "right-0"
    }

    return {
      leftPosition,
      rightPosition
    }
    
  },
  methods: {
    changeLocale: function (locale: string) {
      localStorage.setItem('locale', locale)
      this.$i18n.locale = locale
      this.$store.commit('changeLocale', locale);
    }
  },
});


import { defineComponent, inject, reactive, ref } from 'vue'

export default defineComponent({
  name: 'StratistiquesView',
  setup() {
    // get emitter
    const $ContentManagerUrl = inject('$ContentManagerUrl')

    

    // get Availability periods from Lodgify (or Database if already fetched)
    const availabilities = reactive({lodgify_response:{}})
    const lodgifyGetAvailability = async() => {
      const response = await fetch($ContentManagerUrl+"/lodgify-calls/month-availability?"+ new URLSearchParams({
        start: '2021-06-01',
        end: '2021-06-30',
        propertyId: '329673',
      }), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('jwt')}`
        },
      })

      if(response.ok) {
        console.log(response)
        availabilities.lodgify_response = await response.json()
      } else {
        throw Error(response.statusText);
      }

    }

    return {

      availabilities,
      lodgifyGetAvailability,
    }
  }
})

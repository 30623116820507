
import { defineComponent, computed, reactive, ref } from 'vue'
import { useQuery, useResult } from '@vue/apollo-composable'
import { searchHelper, toSearchList } from '@/modules/search'
import { DatePicker } from 'v-calendar'

import searchReservationsQuery from '@/graphql/reservation/search.gql'
import getScisQuery from '@/graphql/sci/gets.gql'

export default defineComponent({
  name: 'UserForm',
  props: ['element', 'display', 'type'],
  components: {
    DatePicker
  },
  setup() {
    const preselectedReservation = ref({ id:0, name:'' })

    interface InvoiceForm {
      name: string,
      custom_products: any[],
      client_name: string,
      client_email: string,
      client_phone: string,
      date: Date | null,
      client_arrival: null | Date,
      client_departure: null | Date,
      paid: number | null,
      due: number | null

      reservationId: string,
      sciId: number
    }

    const form: InvoiceForm  = reactive({
      name: '',
      custom_products: [] as any[],
      client_name: '',
      client_email: '',
      client_phone: '',
      date: null,
      client_arrival: null,
      client_departure: null,
      paid: null,
      due: null,

      reservationId: "0",
      sciId: 1
    })

    ////////// Field : Reservation - Search
    const { searchFunction: searchReservations, ElementsList: searchedReservations } = searchHelper('reservations', searchReservationsQuery)

    const searchedReservationsList = computed(() => {
      return toSearchList(searchedReservations, 'reservation')
    })

    ////////// Field : SCI - Gets
    const { result: getScisQR } = useQuery(getScisQuery)
    const scis = useResult(getScisQR, null, data => data.scis)

    const addCustomProductToFormList = () => {
      form.custom_products.push({name: "", custom_text: "", price: 0, quantity: 1, type: "custom"})
      console.log(form.custom_products)
    }
    const removeCustomProductFromFormList = (index: number) => {
      form.custom_products.splice(index, 1)
    }


    // Testing purposes
    const fillForm = () => {
      form.name = 'test_name'
      form.client_name = 'test_client_name'
      form.client_email = 'test_client_email'
      form.client_phone = 'test_client_phone'
      form.date = new Date(1804095567183),
      form.client_arrival = new Date(1501045567183),
      form.client_departure = new Date(1504095967183),
      form.paid = 1000
      form.due = 2000
    }

    return  {
      form,

      searchReservations,
      searchedReservationsList,
      preselectedReservation,

      addCustomProductToFormList,
      removeCustomProductFromFormList,

      scis,

      fillForm
    }
  }
})


import { defineComponent, inject, computed, reactive, ref } from 'vue'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { Emitter } from 'mitt'
import { helpers, invoiceHelpers } from '@/modules/helpers'
import { DatePicker } from 'v-calendar'
import getInvoicesQuery from '@/graphql/invoice/gets_bySci.gql'
import getInvoiceBatchQuery from '@/graphql/invoice/gets_batch.gql'
import getInvoicesByIdsQuery from '@/graphql/invoice/gets_byIds.gql'
import getScisQuery from '@/graphql/sci/gets.gql'
import generateInvoiceMutation from '@/graphql/invoice/generate.gql'
import generateCreditNoteMutation from '@/graphql/invoice/generateCreditNote.gql'
import sendByEmailInvoiceMutation from '@/graphql/invoice/sendByEmail.gql'

import InvoiceUpdateModal from '@/components/modals/InvoiceUpdate.vue'

export default defineComponent({
  name: 'InvoicesView',
  components: {
    InvoiceUpdateModal,
    DatePicker
  },
  setup() {
    // get Global properties
    const emitter = inject('$emitter') as Emitter

    // generate Invoice PDF
    const { loading: generateInvoiceLoading, mutate: _generateInvoice, onDone: generateInvoiceDone } = useMutation(generateInvoiceMutation)

    const generateInvoice = (id: number) => {
      const queryInput = {
        id: id
      }
      _generateInvoice(queryInput)
    }
    generateInvoiceDone(() => {
      emitter.emit("create-toast", {
        props: {
          title: 'PDF Généré',
          text: "Le PDF de la facture a été généré avec succès.",
          icon: "check-circle",
        }
      })
    })

    // generate CreditNote PDF
    const { loading: generateCreditNoteLoading, mutate: _generateCreditNote, onDone: generateCreditNoteDone } = useMutation(generateCreditNoteMutation)

    const generateCreditNote = (id: number) => {
      const queryInput = {
        id: id
      }
      _generateCreditNote(queryInput)
    }
    generateCreditNoteDone(() => {
      emitter.emit("create-toast", {
        props: {
          title: 'PDF Généré',
          text: "Le PDF de la facture d'avoir a été généré avec succès.",
          icon: "check-circle",
        }
      })
    })

    let selectedYear: any = ref('')

    const selectedYearRange = computed(() => {
      return selectedYear.value === '' ? {} : {
        date_gte: selectedYear.value+'-01-01',
        date_lte: selectedYear.value+'-12-31'
      }
    })


    const selectYear = (e: any) => {
      if(e.target.classList.contains('selected')) {
        e.target.classList.remove('selected')
        selectedYear.value = ''
      } else {
        selectedYear.value = e.target.innerText
        document.querySelectorAll('.years-buttons button').forEach(e => {
          e.classList.remove('selected')
        })

        e.target.classList.add('selected')
      }
    }

    let selectedMonths: any[] = reactive([])

    const toggleMonthRange = (monthNumber: any) => {
      let i = selectedMonths.indexOf(monthNumber)
      if(i >= 0) {
        selectedMonths.splice(i,1)
      } else {
        selectedMonths.push(monthNumber)
      }
    }

    const months = new Array(12).fill(0).map((_, i) => {
      let date = new Date(`${i + 1}/1`)
      return {
        monthName: date.toLocaleDateString(undefined, {month: 'long'}),
        monthNumber: date.toLocaleDateString(undefined, {month: '2-digit'})
      }
    })

    const selectedMonthsRanges = computed(() => {
      return selectedMonths.map((month: any) => {
        var d = new Date(selectedYear.value, month, 0);
        return {
          date_gte: selectedYear.value+'-'+month+'-01',
          date_lte: selectedYear.value+'-'+month+'-'+d.toLocaleDateString(undefined, {day: '2-digit'})
        }
      })
    })

    const selectedRanges = computed(() => {
      if(selectedYear.value === '') {
        return {}
      } else {
        if(selectedMonths.length === 0) {
          return selectedYearRange.value
        } else {
          return selectedMonthsRanges.value
        }
      }
    })

    const years: number[] = [2020]
    const nextYear = new Date().getFullYear() + 1

    while (years[years.length - 1] < nextYear) {
      years.push(years[years.length - 1] + 1)
    }

    // sendByEmail Invoice
    const { mutate: _sendByEmailInvoice } = useMutation(sendByEmailInvoiceMutation)

    const sendByEmailInvoice = (id: number) => {
      const queryInput = {
        id: id
      }
      _sendByEmailInvoice(queryInput)
    }

    // get Invoices list
    const invoiceListPaging = reactive({
      arisma: {
        start: 0,
        limit: 100,
        max: 0
      },
      maisar: {
        start: 0,
        limit: 100,
        max: 0
      },
      arnaud: {
        start: 0,
        limit: 100,
        max: 0
      },
      matthieu: {
        start: 0,
        limit: 100,
        max: 0
      }
    })
    const { result: getInvoicesResult, loading: getInvoicesLoading, error: getInvoicesError, refetch: refetchInvoices } = useQuery(getInvoicesQuery, () => ({ start: invoiceListPaging.arisma.start, limit: invoiceListPaging.arisma.limit, dateRanges: selectedRanges.value}))
    const invoices_arisma = useResult(getInvoicesResult, null, data => data.invoices_arisma)
    const invoices_maisar = useResult(getInvoicesResult, null, data => data.invoices_maisar)
    const invoices_arnaud = useResult(getInvoicesResult, null, data => data.invoices_arnaud)
    const invoices_matthieu = useResult(getInvoicesResult, null, data => data.invoices_matthieu)
    const invoicesCountArisma = useResult(getInvoicesResult, null, data => data.count_invoices_arisma.aggregate.count)
    const invoicesCountMaisar = useResult(getInvoicesResult, null, data => data.count_invoices_maisar.aggregate.count)
    const invoicesCountArnaud = useResult(getInvoicesResult, null, data => data.count_invoices_arnaud.aggregate.count)
    const invoicesCountMatthieu = useResult(getInvoicesResult, null, data => data.count_invoices_matthieu.aggregate.count)
    invoiceListPaging.arisma.max = invoicesCountArisma as any
    invoiceListPaging.maisar.max = invoicesCountMaisar as any
    invoiceListPaging.arnaud.max = invoicesCountArnaud as any
    invoiceListPaging.matthieu.max = invoicesCountMatthieu as any

    emitter.on('invoices-refresh', () => {
      refetchInvoices()
    })

    // Get scis
    const { result: getScisResult } = useQuery(getScisQuery)
    const scis = useResult(getScisResult, null, data => data.scis)

    // get Invoices list
    const invoiceBatchListPaging = reactive({
      start: new Date(),
      limit: new Date(),
      sci: undefined,
      count: 0
    })

    // 
    const selectedInvoices: number[] = reactive([])
    const toggleInvoiceSelection = (id: number) => {
      let i = selectedInvoices.indexOf(id)
      if(i >= 0) {
        selectedInvoices.splice(i,1)
      } else {
        selectedInvoices.push(id)
      }
    }

    const batchMode = computed(() => {
      if(selectedInvoices.length === 0) {
        return 'dateRange'
      } else {
        return 'ids'
      }
    })

    const { result: getInvoiceBatchResult, loading: getInvoiceBatchLoading, onResult: getInvoiceBatchOnResult } = useQuery(getInvoiceBatchQuery, () => ( {start: helpers.dateobjToISO(invoiceBatchListPaging.start), limit: helpers.dateobjToISO(invoiceBatchListPaging.limit), sci:invoiceBatchListPaging.sci} ), () => ({enabled: batchMode.value === 'dateRange' ? true : false}) )
    const invoiceBatch = useResult(getInvoiceBatchResult, null, data => data.invoiceBatch)
    const invoiceBatchCount = useResult(getInvoiceBatchResult, null, data => data.invoiceBatchCount.aggregate.count)
    invoiceBatchListPaging.count = invoiceBatchCount as any

    
    const invoiceBatchWithFileCount = ref(0)
    getInvoiceBatchOnResult (QR => {
      invoiceBatchWithFileCount.value = 0
      for(const invoice in invoiceBatch.value) {
        if(invoiceBatch.value[invoice].file) {
          invoiceBatchWithFileCount.value++
        }
      }
    })

    const { result: getInvoiceByIdsResult, loading: getInvoiceByIdsLoading, onResult: getInvoiceByIdsOnResult } = useQuery(getInvoicesByIdsQuery, () => ( {ids: selectedInvoices} ), () => ({enabled: batchMode.value === 'ids' ? true : false}))
    const invoicesByIds = useResult(getInvoiceByIdsResult, null, data => data.invoicesByIds)


    // Invoice ID sent to Modal for query
    const updateId = ref(0)
    const setupEditModal = (id: number) => {
      updateId.value = id 
      emitter.emit("open-modal-invoice-update")
    }

    const buttonSentence = computed(() => {
      if(getInvoiceBatchLoading.value) {
        return 'Chargement...'
      } else {
        if(invoiceBatchListPaging.count === 0) {
          return 'Aucune facture trouvée'
        } else {
          return invoiceBatchListPaging.count+' facture(s), dont '+invoiceBatchWithFileCount.value+' avec PDF.'
        }
      }
    })

    

    return {
      generateInvoice,
      generateCreditNote,
      sendByEmailInvoice,

      invoices_arisma,
      invoices_maisar,
      invoices_arnaud,
      invoices_matthieu,
      invoiceListPaging,
      getInvoicesLoading,
      getInvoicesError,

      scis,
      batchMode,
      years,
      invoiceBatchListPaging,
      invoiceBatch,
      invoiceBatchCount,
      invoiceBatchWithFileCount,
      invoicesByIds,

      setupEditModal,
      updateId,
      emitter,

      buttonSentence,
      selectYear,
      selectedYear,
      selectedYearRange,
      selectedMonths,
      selectedMonthsRanges,
      toggleMonthRange,
      months,

      toggleInvoiceSelection,

      helpers, invoiceHelpers
    }
    
  }
})

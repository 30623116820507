// disable linter camelCase BS
/*eslint-disable */
export const fr = {
  home: {
    menu: {
      video: "Accueil",
      availability: "Réserver",
      visit: "Visiter",
      partners: "Partenaires",
      testemonies: "Témoignages",
      contact: "Contact",
      fees: "15% de frais supplémentaires"
    },
    video: {
      descriptions: {
        1: {
          highlight1: "Superbes villas",
          normal1: "pouvant accueillir",
          highlight2: "jusqu'à 15 personnes"
        },
        2: {
          normal1: "Un",
          highlight1: "espace SPA",
          normal2: "totalement privé",
          highlight2: "accessible 24h/24",
        },
        3: {
          normal1: "Située à seulement",
          highlight1: "5 minutes de Strasbourg",
        },
        4: {
          normal1: "Passez un agréable moment",
          highlight1: "en famille",
          normal2: "ou",
          highlight2: "entre amis",
        }
      },
      title: "Cinq airbnb de haut standing pour vos vacances, à cinq minutes seulement de Strasbourg !",
    },
    availability: {
      title: "Réserver nos biens",
      h2: "Visualisez d'un coup d'œil nos créneaux disponibles",
      notes: {
        title: "Quelques notes",
        direct: "Réserver directement depuis ce site vous garantit le meilleur prix possible sur la réservation, car vous évitez les frais de plateforme qui sont inévitablement répercutés sur le client.",
        duration: "La durée de réservation minimum possible est de deux jours consécutifs, sauf le dimanche soir (cette règle ne s'applique pas au Studio 14).",

        options: {
          title: "Options",
          jacuzzi: "L'accès au spa est soumis au règlement d'un forfait de 50€ pour le séjour pour l'ensemble des équipements (jacuzzi, sauna et rétro-projecteur Netflix).",
          animals: "Les animaux sont acceptés. Ils induisent toutefois un surcout de ménage de 30€ pour le séjour."
        },
        fees: {
          title: "Frais supplémentaires",
          cleaning: "Un forfait de ménage à hauteur de 80/40/20 € (pour une Maison/Appartement/Studio respectivement) sont appliqués à toutes les réservations.",
          tourist_tax: "Une taxe de séjour de 1€ par personne et par jour est appliquée obligatoirement à toute réservation."
        },
        other: {
          title: "Autres remarques",
          payment: "Au moment de la réservation, seul 1€ est versé depuis votre carte bancaire. Le reste dû sera prélevé après la fin de votre séjour.",
          see_booklet: "Vous pouvez consulter l'ensemble de nos conditions d'accueil dans notre",
          see_ruleset: "ainsi que nos règlements dans notre"
        }
      },
      popovers: {
        0: "Journée réservée",
        1: "Peut être la fin d'un séjour",
        2: "Date libre"
      },
      tooManyUsers: "Ce logement ne peut accueillir que {0} personnes au maximum",
      button: {
        book: "Réservez en ligne",
        details: "Plus de détails",
        loading: "Recherche...",
        select: "Selectionez une période...",
        error: "Logement non disponible, veuillez essayer une autre période"
      }
    },
    presentation: {
      title: "Découvrez nos airbnbs",
      minStay: "La durée de séjour minimum est de deux jours sauf le dimanche soir.",
      nominStay: "Pas de durée minimum pour ce logement.",
    },
    testemonies: {
      title: "L'avis de nos clients"
    },
    contact: {
      title: "Besoin d'un renseignement ?",
      name: "Vos nom et prénom",
      email: "Votre adresse e-mail",
      by_mail: {
        subTitle: "Par email",
        fill_form: "Remplissez le formulaire et nous reviendrons vers vous dans les plus brefs délais."
      },
      by_phone: {
        subTitle: "Appelez nous au",
        call: "Par téléphone",
        your_number: "Votre numéro de téléphone",
        called_back: "Ou, si vous souhaitez être rappelé, laissez nous votre numéro de téléphone et nous vous contacterons dans la journée.",
      },
      message: "Écrivez votre message ici",
      send: "Envoyer",
      sent: "Message envoyé !"
    },
    footer: {
      legal_notice: {
        name: "Mentions légales"
      },
      welcome_booklet: {
        name: "Livret d'accueil"
      },
      rules_booklet: {
        name: "Règlement intérieur"
      }
    },
    selectDate: "Dates",
    selectNumber: "Nombre de voyageurs",
    search_btn: "Rechercher"
  },
  visit: {
    title: "Visiter nos biens",
    icons: {
      spa: "Espace Spa",
      jacuzzi: "Jacuzzi",
      sauna: "Sauna",
      projector: "Rétro-projecteur avec Netflix",
      arcade: "Borne d'arcade",
      barbecue: "Barbecue",
      aircon: "Climatisation",
      petanque: "Petanque",
      futbol: "Baby-foot",
      table_tennis: "Ping-pong",
      wifi: "Wi-fi",
      fridge: "Frigo avec congelateur",
      american_fridge: "Frigo américain",
      small_fridge: "Petit frigo",
      dishwasher: "Lave vaisselle",
      oven: "Four",
      microwave: "Micro-ondes",
      coffee_maker: "{n} Machine a café | {n} Machines a café",
      ustensils: "Des couverts pour {0} personnes"
    }
  },
  cookies: {
    sentence: "Nous utilisons les cookies afin de fournir les services et fonctionnalités proposés sur notre site et afin d’améliorer l’expérience de nos utilisateurs. Les cookies sont stockés sur votre ordinateur ou sur tout autre appareil et ne seront jamais utilisés à d'autes fins.",
    button: "Je comprend et j'accepte."
  },
  words: {
    and: "et",
    for: "pour",
    bedroom: "chambre | chambres",
    beds: {
      simple: "lit simple | lits simples",
      double: "lit double | lits doubles",
      couch: "canapé-lit | canapés-lits",
    },
    person: "personne | personnes",
    call_us: "Nous appeller",
    villa: "La villa",
    apartment: "L'appartement",
    studio: "Le studio",
  },
  chapters: "Chapitres",
  titles: {
    welcome: {
      h2: "Bienvenue !"
    },
    beforeArrival: {
      h2: "Avant votre arrivée",
      howToFind: {
        h3: "Comment se rendre au logement ?",
        address: "L'adresse",
        car: "En voiture",
        train: "En train",
        bus: "En bus",
        plane: "En avion"
      },
      hours: {
        h3: "Horaires"
      }
    },
    onArrival: {
      h2: "À votre arrivée",
      whereToPark: {
        h3: "Où se garer ?"
      },
      goldenRules: {
        h3: "Nos trois règles d'or"
      },
      products: {
        h3: "Produits fournis / produits à prévoir"
      },
      payment: {
        h3: "Paiement du séjour (si réservation en direct)"
      },
      keys: {
        h3: "Remise des clés"
      }
    },
    manuals: {
      h2: "Notices d’utilisation des équipements",
      jacuzzi: {
        h3: "Jacuzzi"
      },
      sauna: {
        h3: "Sauna"
      },
      barbecue: {
        h3: "Barbecue"
      },
      wifi: {
        h3: "Réseau Wifi"
      },
      dishwasher: {
        h3: "Lave-vaisselle"
      },
      toilets: {
        h3: "Sanitaires"
      },
      sorting_trash: {
        h3: "Poubelles de tri"
      },
      glass_trash: {
        h3: "Poubelles à verre"
      }
    },
    practicalInfo: {
      h2: "Infos pratiques",
      shops: {
        h3: "Faires ses courses et acheter du carburant"
      },
      shops_sunday: {
        h3: "Le dimanche"
      },
      doctors: {
        h3: "Médecins, hopitaux, pharmacies"
      },
      other: {
        h3: "Autres"
      }
    },
    goToPlaces: {
      h2: "Bonnes adresses",
      restaurant_selection: {
        h3: "Notre sélection de restaurants"
      },
      other_restaurants: {
        h3: "Autres restaurants"
      },
      gourmet_addresses: {
        h3: "Nos adresses pour les fins gourmets",
        cheese: {
          h4: "Fromage"
        },
        wines: {
          h4: "Vin"
        },
        bakeries: {
          h4: "Boulangeries"
        },
        pastries: {
          h4: "Pâtisseries"
        },
        caterers: {
          h4: "Traiteurs"
        },
        vegetables: {
          h4: "Légumes"
        },
        flowers: {
          h4: "Fleuriste"
        }
      },
    },
    closeActivities: {
      h2: "Activités proches",
      amusement_parcs: {
        h3: "Parcs d’attraction"
      },
      museeums: {
        h3: "Musées / Monuments"
      },
      sport_activities: {
        h3: "Activités sportives"
      },
      other: {
        h3: "Autres"
      }
    },
    uponLeaving: {
      h2: "À votre départ",
      furniture: {
        h3: "Mobilier"
      },
      equipment: {
        h3: "Équipements"
      },
      windows: {
        h3: "Fenêtres et lumières"
      },
      trash: {
        h3: "Poubelles"
      },
      towels: {
        h3: "Draps & serviettes"
      },
      rate_us: {
        h3: "Notez nous !"
      }
    },
    other_mentions: {
      h2: "Autres mentions",
      video_surveillance: {
        h3: "Video-surveillance"
      },
      fire_safety: {
        h3: "Sécurité incendie"
      },
      remark: {
        h3: "Remarque"
      }
    },
    closing_words: {
      h2: "Mot de fin"
    },
    behavior: {
      name: "Comportement"
    },
    noise: {
      name: "Bruit"
    },
    tobbaco: {
      name: "Tabac"
    },
    upkeep: {
      name: "Entretien"
    },
    trash: {
      name: "Poubelles"
    },
    rooms: {
      name: "Chambres"
    },
    water_electricity_heating: {
      name: "Eau, Électricité, Chauffage"
    },
    pets: {
      name: "Animaux Domestiques"
    },
    toilets: {
      name: "Sanitaires"
    },
    bbq: {
      name: "Barbecue"
    },
    insurance: {
      name: "Assurance"
    },
    fire_safety: {
      name: "Sécurité Incendie"
    },
    leaving: {
      name: "Départ"
    },
    jacuzzi: {
      name: "Jacuzzi"
    },
    sauna: {
      name: "Sauna"
    },
    caution_table: {
      name: "Tableau de caution"
    },
    sort_guide: {
      name: "Guide de tri communal"
    },
    glass_bins: {
      name: "Emplacement des conteneurs à verre"
    },
    jacuzzi_manual: {
      name: "Manuel d’utilisation du jacuzzi"
    }
  },
  biens: {
    pageTitle: "Gites & Spa Strasbourg",
    bien_select_text: "Sélectionnez le gîte dans lequel vous vous trouvez actuellement :",
    welcome_booklet: {
      pageTitle: "Livret d'accueil"
    },
    rules_booklet: {
      pageTitle: "Règlement intérieur",
      equipment_notice: "Notices des équipements",
      see_on_map: "Voir sur Google Maps"
    },
    h2: {
      overview: "Vue d'ensemble",
      other: "En bref",
      reviews: "Une qualité reconnue",
      convinced: "Convaincus ? Reservez dès maintenant !"
    },
    'gite-des-freres': {
      short: "Villa de 250m² avec Spa privé, Sauna, Barbecue, Babyfoot, Petanque et bien plus",
      long: {
        intro: "Superbe villa privative de 250 m² avec jacuzzi et sauna pouvant accueillir jusqu'à 15 personnes dans des conditions haut de gamme. Située à 5 minutes de Strasbourg, cette maison sera l'endroit idéal pour pour passer une agréable moment en famille ou entre amis, et vous détendre tout en visitant notre belle région.",
      },
      presentation: {
        title: "Une villa d'exception",
        h2: {
          activities: "Des loisirs pour tous les âges",
          rooms: "Des chambres dignes de l'hôtel",
          equipments: "De nombreux équipements",
        }
      },
      photos_captions: {
        spa: "Le jacuzzi 5 places.",
        spa_space: "Petit espace commun près du jacuzzi.",
        sauna: "Le sauna trois places.",
        futbol:"Baby-foot Sulpice haut de gamme.",
        table_tennis:"Table de ping-pong couverte.",
        petanque:"Terrain de pétanque.",
        arcade:"Borne d'arcade avec plus de 3000 jeux disponibles.",
        barbecue: "Barbecue à gaz Weber.",
        exterior: "Un grand jardin avec terrasse.",
        coffee:"Les quatre machines à café.",
        bathroom:"La salle de bain spacieuse.",
        living:"Salon climatisé.",
        room1: "Chambre 1",
        room2: "Chambre 2",
        room3: "Chambre 3",
        room4: "Chambre 4",
        room5: "Chambre 5",
        room6: "Chambre 6"
      }
    },
    'la-villa-11': {
      short: "Villa 180m² avec Spa privé, Sauna, Barbecue, Babyfoot, Petanque et bien plus",
      long: {
        intro: "",
      },
      presentation: {
        title: "Une villa d'exception",
        h2: {
          activities: "Des loisirs pour tous les âges",
          rooms: "Des chambres dignes de l'hôtel",
          equipments: "De nombreux équipements",
        },
      },
      photos_captions: {
        spa: "Le jacuzzi 5 places.",
        spa_space: "Espace spa avec sauna, jacuzzi et rétro-projecteur Netflix.",
        futbol: "Baby-foot Sulpice haut de gamme.",
        terrace: "Terrasse avec transats.",
        exterior: "Grand espace extérieur.",
        exterior2: "Une grande table ainsi qu'un barbecue Weber sont à disposition pour vos repas.",
        table_tennis: "Table de ping-pong.",
        petanque: "Transats et terrain de pétanque.",
        arcade: "Borne d'arcade avec plus de 3000 jeux disponibles.",
        equipment: "Un aperçu des équipements disponibles.",
        bathroom: "La salle de bain spacieuse.",
        kitchen: "Grande cuisine tout équipée.",
        room1: "Chambre 1",
        room2: "Chambre 2",
        room3: "Chambre 3",
        room4: "Chambre 4",
        room5: "Chambre 5",
      }
    },
    'la-villa-11a': {
      short: "Villa 230m² avec Spa privé, Sauna, Babyfoot, Arcade et bien plus",
      long: {
        intro: "",
      },
      presentation: {
        title: "Une villa d'exception",
        h2: {
          activities: "Des loisirs pour tous les âges",
          rooms: "Des chambres dignes de l'hôtel",
          equipments: "De nombreux équipements",
        },
      },
      photos_captions: {
        spa: "Magnifique spa 6 places.",
        spa_space: "Espace spa avec sauna, jacuzzi et rétro-projecteur Netflix.",
        futbol: "Baby-foot Sulpice haut de gamme.",
        table_tennis: "Table de ping-pong.",
        arcade: "Borne d'arcade avec plus de 3000 jeux disponibles.",
        equipment: "Un aperçu des équipements disponibles.",
        bathroom: "La salle de bain spacieuse.",
        kitchen: "Grande cuisine tout équipée.",
        room1: "Chambre 1",
        room2: "Chambre 2",
        room3: "Chambre 3",
        room4: "Chambre 4",
      }
    },
    'la-villa-11b': {
      short: "Villa 240m² avec Spa privé, Sauna, Babyfoot, Arcade et bien plus",
      long: {
        intro: "",
      },
      presentation: {
        title: "Une villa d'exception",
        h2: {
          activities: "Des loisirs pour tous les âges",
          rooms: "Des chambres dignes de l'hôtel",
          equipments: "De nombreux équipements",
        },
      },
      photos_captions: {
        spa: "Magnifique spa 6 places.",
        spa_space: "Espace spa avec sauna, jacuzzi et rétro-projecteur Netflix.",
        futbol: "Baby-foot Sulpice haut de gamme.",
        table_tennis: "Table de ping-pong.",
        arcade: "Borne d'arcade avec plus de 3000 jeux disponibles.",
        equipment: "Un aperçu des équipements disponibles.",
        bathroom: "La salle de bain spacieuse.",
        kitchen: "Grande cuisine tout équipée.",
        room1: "Chambre 1",
        room2: "Chambre 2",
        room3: "Chambre 3",
        room4: "Chambre 4",
      }
    },
    'la-villa-15': {
      short: "Villa 200m² avec Spa privé, Sauna, Barbecue, Babyfoot, Petanque et bien plus",
      long: {
        intro: "",
      },
      presentation: {
        title: "Une villa d'exception",
        h2: {
          activities: "Des loisirs pour tous les âges",
          rooms: "Des chambres dignes de l'hôtel",
          equipments: "De nombreux équipements",
        },
      },
      photos_captions: {
        spa: "Espace spa avec sauna, jacuzzi 5 places et rétro-projecteur Netflix.",
        spa_space: "Le sauna d'angle.",
        futbol: "Baby-foot Sulpice haut de gamme.",
        table_tennis: "Table de ping-pong extérieure.",
        petanque: "Terrain de pétanque.",
        arcade: "Borne d'arcade avec plus de 3000 jeux disponibles.",
        equipment: "Un aperçu des équipements disponibles.",
        raclette: "L'appareil à raclette.",
        bathroom: "La salle de bain spacieuse.",
        kitchen: "Grande cuisine tout équipée.",
        room1: "Chambre 1",
        room2: "Chambre 2",
        room3: "Chambre 3",
        room4: "Chambre 4",
        room5: "Chambre 5",
        room6: "Chambre 6"
      }
    },
    'gite-le-14': {
      short: "Appartement design avec jacuzzi, sauna, terrasse et climatisation",
      long: {
        intro: "",
      },
      presentation: {
        title: "Un appartement tout confort",
        h2: {
          activities: "Des loisirs pour tous les âges",
          rooms: "Des chambres aménagées pour votre confort",
          equipments: "De nombreux équipements",
        },
      },
      photos_captions: {
        rating: "Gite certifié 4 étoiles.",
        spa: "Le jacuzzi 6 places et la terrasse.",
        equipment: "Un aperçu des équipements disponibles.",
        raclette: "L'appareil à raclette.",
        kitchen: "Grande cuisine tout équipée.",
        coffee: "Les quatre machines à café.",
        bathroom: "La salle de bain avec Sauna en érable rouge.",
        barbecue: "Barbecue à gaz Weber.",
        exterior: "Profitez d'un bel extérieur aménagé pour vos diners.",
        room1: "Chambre 1",
        room2: "Chambre 2"
      }
    },
    'studio-le-14': {
      short: "Studio design avec jacuzzi, terrasse privée et climatisation",
      long: {
        intro: "",
      },
      presentation: {
        title: "Le studio idéal pour vos vacances",
        h2: {
          activities: "Des loisirs pour tous les âges",
          rooms: "Des chambres aménagées pour votre confort",
          equipments: "Des équipement de qualité",
        },
      },
      photos_captions: {
        spa: "Le jacuzzi 5 places et la petite terrasse.",
        living: "Beau salon climatisé avec TV \"The Frame\" de Samsung",
        living2: "Vue d'ensemble du studio.",
        kitchen: "Cuisine fonctionelle avec micro-ondes.",
        bathroom: "Sanitaires et salle de bains.",
        equipment: "Un aperçu des équipements disponibles."
      }
    },
    'villa-le-14': {
      short: "Villa 170m² avec Spa privé, Piscine, Sauna, Barbecue et bien plus",
      long: {
        intro: "",
      },
      presentation: {
        title: "Une villa d'exception",
        h2: {
          activities: "Des loisirs pour tous les âges",
          rooms: "Des chambres dignes de l'hôtel",
          equipments: "De nombreux équipements",
        },
      }
    },
  },
  partners: {
    name: "Nos partenaires",
    discover: "Découvrez",
    sainte_maxime: "Très bel appartement situé à Sainte-Maxime, sur la côte d'Azur. Il est équipé de terasse, piscine, spa, gym, balneo et sauna pour un séjour de détente garanti."
  }
}